document.addEventListener("DOMContentLoaded", () => {
	// Get references to all modal elements
	const modals = document.querySelectorAll(".modal");
	const overlay = document.querySelector(".overlay");
	const closeBtns = document.querySelectorAll(".close-modal");
	const openModalBtns = document.querySelectorAll(".open-modal");

	function enableSubmit(checkboxes, submit) {
		const checked = Array.from(checkboxes).every((checkbox) => checkbox.checked);
		submit.classList.toggle("disabled", !checked);
	}

	// Function to close all modals and overlay
	function closeAllModals() {
		modals.forEach((modal) => {
				modal.style.display = "none";
		});
		overlay.style.display = "none";
	}

	// Loop through each close button and attach event listeners
	closeBtns.forEach((closeBtn) => {
		closeBtn.addEventListener("click", () => {
				const modalId = closeBtn.dataset.modalId;
				const modal = document.getElementById(modalId);
				modal.style.display = "none";
				overlay.style.display = "none"; // Close the overlay
		});
	});

	// Loop through each open button and attach event listeners
	openModalBtns.forEach((openBtn) => {
		openBtn.addEventListener("click", () => {
				const modalId = openBtn.dataset.modalId;
				openModal(modalId);
		});
	});

	// Function to open a modal
	function openModal(modalId) {
		const modal = document.getElementById(modalId);
		modal.style.display = "block";
		overlay.style.display = "block"; // Display the overlay
	}

	// Loop through each overlay and attach event listener to close all modals
	overlay.addEventListener("click", () => {
		closeAllModals();
	});

	// Loop through each modal to handle Escape key
	modals.forEach((modal) => {
		document.addEventListener("keydown", (evt) => {
			evt = evt || window.event;
			const isEscape = evt.key === "Escape" || evt.key === "Esc";
			if (isEscape) {
				modal.style.display = "none";
				closeAllModals();
			}
		});

		// Prevent clicks within the modal content from closing the modal
		modal.querySelector(".modal-content").addEventListener("click", (event) => {
			event.stopPropagation();
		});

		const checkboxes = modal.querySelectorAll(".term-checkbox");
		const submit = modal.querySelector(".term-submit");

		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("click", () => {
					enableSubmit(checkboxes, submit);
			});
		});

	});

});
